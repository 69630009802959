import React from 'react';
import { T } from 'lioness';
import PropTypes from 'prop-types';
import { TextField, CardFooter, Container, Tag } from 'src/components/IMUI';
import TagSelector from 'src/components/TagSelector/TagSelector';
import createQuestion from './createQuestion';
import UploadAttachment from 'src/components/Survey/UploadAttachment';
import cls from './TextQuestion.module.css';
import { Icon } from 'im/ui/Icon';
import classNames from 'classnames/bind';
const cx = classNames.bind(cls);

export const TagNotProvided = () => (
  <Tag
    renderIconLeft={() => <Icon name="tag" />}
    square
    warning
    outline
    label="option tags for this question were not provided in the survey editor"
    informative
  />
);

@createQuestion
export default class TextQuestion extends React.PureComponent {
  static propTypes = {
    multiLine: PropTypes.bool,
    question: PropTypes.object.isRequired,
    answer: PropTypes.object,
    surveyAnswers: PropTypes.object,
    tagsWithTagGroups: PropTypes.array.isRequired,
    isReview: PropTypes.bool,
    onChange: PropTypes.func,
    value: PropTypes.string,
    readOnly: PropTypes.bool,
    index: PropTypes.number,
    isEditing: PropTypes.bool,
    onRefresh: PropTypes.func,
    isAnswerMode: PropTypes.bool,
    onTagsChange: PropTypes.func,
  };

  static validate(question, _value) {
    if (typeof _value === 'object' && _value !== null)
      return <T>Unexpected value type</T>;
    const parsed = String(_value ?? '')?.trim();
    const shouldValidate = question.required;
    if (parsed.length == 0 && shouldValidate) return <T>Missing answer</T>;
    return null;
  }

  onDeleteTag = (tagId) => {
    this.props.onTagsChange?.(
      this.props.answer.tags.filter((tid) => tid !== tagId)
    );
  };
  onTagSelectChange = (tagIds = [], tags = []) => {
    this.props.onTagsChange?.(
      Array.isArray(tagIds) ? tagIds : [tagIds],
      Array.isArray(tags) ? tags : [tags]
    );
  };
  getTag = (tagId) =>
    this.props.tagsWithTagGroups.find((st) => st.id === tagId);

  renderTagsSelector() {
    const availableTagIds = (this.props.question.tags || [])
      .map((i) => this.getTag(i))
      .filter(Boolean)
      .map((tag) => tag.id);
    const availableSelectedTagIds = (this.props.answer.tags || [])
      .map((i) => this.getTag(i))
      .filter(Boolean)
      .map((tag) => tag.id)
      .filter((tagId, index, tagIds) => tagIds.indexOf(tagId) === index);
    if (
      this.props.isReview &&
      this.props.readOnly &&
      !availableSelectedTagIds.length
    )
      return null;
    const selectorText = this.props.question.multiTags
      ? 'Add multiple tags that best match your response'
      : 'Add a tag that best matches your response';

    return (
      <TagSelector
        isAnswerMode={this.props.isAnswerMode}
        readOnly={this.props.readOnly}
        allowCreate={false}
        selected={availableSelectedTagIds}
        multiple={this.props.question.multiTags}
        tagsWithTagGroups={this.props.tagsWithTagGroups}
        availableTagIds={availableTagIds}
        filter={(tag) => availableTagIds.indexOf(tag.id) !== -1}
        onChange={this.onTagSelectChange}
        onRemove={this.onDeleteTag}
        noTagProps={{
          label: selectorText,
          outline: true,
          className: cls.selectorButtonText,
        }}
      />
    );
  }
  // only in review mode when editing and answer mode
  renderTags() {
    if (
      (!this.props.isEditing &&
        !this.props.readOnly &&
        !this.props.tagsWithTagGroups.length) ||
      this.props.question.disableRespondentTagging
    )
      return null;
    const availableTagIds = (this.props.question.tags || [])
      .map((i) => this.getTag(i))
      .filter(Boolean)
      .map((tag) => tag.id);
    return (
      <CardFooter minimal className="printHidden">
        <Container horizontal>
          {!availableTagIds.length && this.props.isEditing && (
            <TagNotProvided />
          )}
          {availableTagIds.length > 0 && <div>{this.renderTagsSelector()}</div>}
        </Container>
      </CardFooter>
    );
  }

  render() {
    const { value, readOnly, index, isAnswerMode, multiLine } = this.props;
    return (
      <div>
        <TextField
          multiLine
          rows={multiLine ? 8 : 1}
          rowsMax={15}
          name={`text_${index}`}
          value={value ?? ''}
          onChange={(v) => this.props.onChange?.(v)}
          readOnly={readOnly}
          noValidation
          fullWidth
          flat={!isAnswerMode}
          border={!isAnswerMode}
          flatDark={isAnswerMode}
          wrapperClassName="printHidden"
        />
        <div
          className={cx(cls.printTextField, cls.printVisible, {
            [cls.printTextFieldEmpty]: !value,
            [cls.printTextFieldSingle]: !multiLine,
            [cls.printTextFieldMultiline]: multiLine,
          })}
        >
          {value ?? ''}
        </div>
        {this.renderTags()}
        {this.props.question.has_attachment && this.props.isAnswerMode && (
          <CardFooter noPadding className="printHidden">
            <UploadAttachment
              isReview={false}
              questionId={this.props.question.id}
              surveyAnswerId={this.props.answer.uid}
              importUpload={this.props.answer.import_upload}
              projectId={this.props.surveyAnswers.info?.projectId}
              granteeSurveyId={this.props.surveyAnswers.info?.grantee_survey_id}
              onRefresh={this.props.onRefresh}
            />
          </CardFooter>
        )}
      </div>
    );
  }
}
