import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import tagCategoriesSelectorApi from 'src/api/TagCategoriesSelector';
import { where } from 'im/api/Query';

const withAccessSupport = (WrappedComponent) => {
  class TagSelector extends React.PureComponent {
    static propTypes = {
      surveyPublic: PropTypes.object,
      isAnswerMode: PropTypes.bool,
      project: PropTypes.object,
      tagCategories: PropTypes.object,
      tagsWithTagGroups: PropTypes.array,
      getTagCategoriesPrivate: PropTypes.func.isRequired,
      getTagCategoriesPublic: PropTypes.func.isRequired,
      availableTagIds: PropTypes.array,
    };

    state = { tagCategories: [] };

    componentDidMount() {
      if (window.project_need_tags_refresh) {
        this.refreshTagCategories();
      }
    }
    componentDidUpdate(oldProps) {
      if (oldProps.availableTagIds !== this.props.availableTagIds)
        return this.refreshTagCategories();
      if (oldProps.project.uid !== this.props.project.uid)
        return this.refreshTagCategories();
      if (
        window.project_need_tags_refresh &&
        oldProps.tagCategories.data !== this.props.tagCategories.data
      )
        return this.refreshTagCategories();
      if (
        window.project_need_tags_refresh &&
        oldProps.project.enabled_tag_categories !=
          this.props.project.enabled_tag_categories
      )
        return this.refreshTagCategories();
    }
    refreshTagCategories = () => {
      this.props.isAnswerMode
        ? this.handleGetTagCategoriesPublic()
        : this.handleGetTagCategoriesPrivate();
      window.project_need_tags_refresh = false;
    };

    handleGetTagCategoriesPublic = (searchText) => {
      if (!this.props.surveyPublic.data?.survey_token) {
        return this.setState({ tagCategories: [] });
      }

      return this.props
        .getTagCategoriesPublic(
          where()
            .actionMeta({
              headers: {
                Authorization: `Bearer ${this.props.surveyPublic.data.survey_token}`,
              },
              includeMapper: { nested_child_categories: ['child_categories'] },
            })
            .filter('tags_id_eq_any', this.props.availableTagIds)
            .include('nested_child_categories')
            .include('tags')
            .fields('tag', 'title')
            .fields(
              'tag_category',
              'title',
              'tags',
              'child_categories',
              'nested_child_categories',
              'parent'
            )
            .sort('title')
            .paginate({ size: 5000 })
            .distinct()
            .filter(
              searchText
                ? 'nested_categories_with_tags_title_in_any'
                : undefined,
              searchText
            )
        )
        .then((tagCategories) => {
          const filtered = tagCategories?.data.map((cat) => ({
            ...cat,
            tags: cat.tags.filter((tag) =>
              this.props.availableTagIds.includes(tag.id)
            ),
          }));

          this.setState({ tagCategories: filtered });
        });
    };

    handleGetTagCategoriesPrivate = (searchText) => {
      if (!this.props.project.enabled_tag_categories?.length) return;
      this.props.getTagCategoriesPrivate(
        where({
          projectTagCategoryIds: this.props.project.enabled_tag_categories,
        })
          .filter('id_eq_any', this.props.project.enabled_tag_categories)
          .include('tags')
          .fields(
            'tag_category',
            'title',
            'tags',
            'child_categories',
            'nested_child_categories',
            'parent'
          )
          .filter('parent_id_null', true)
          .fields('tag', 'title', 'taggings_count')
          .sort('title')
          .paginate({ size: 5000 })
          .distinct()
          .filter(
            searchText ? 'nested_categories_with_tags_title_in_any' : undefined,
            searchText
          )
      );
    };

    handlePopoverOpen = () => {
      const hasZeroData =
        !this.props.tagCategories.pending.init &&
        !this.props.tagCategories.data.length;
      const hasConstKey = Object.keys(
        this.props.tagCategories.meta.getAction?.queryParams?.filter || {}
      ).find((key) => key.includes('cont'));
      if (!hasZeroData && !hasConstKey) return null;
      return this.props.isAnswerMode
        ? this.handleGetTagCategoriesPublic()
        : this.handleGetTagCategoriesPrivate();
    };

    render() {
      const { isAnswerMode, tagCategories, ...rest } = this.props;
      return (
        <WrappedComponent
          {...rest}
          tagCategories={
            isAnswerMode ? this.state.tagCategories : tagCategories.data
          }
          handleGetTagCategoriesPublic={this.handleGetTagCategoriesPublic}
          handleGetTagCategoriesPrivate={this.handleGetTagCategoriesPrivate}
          onPopoverOpen={this.handlePopoverOpen}
        />
      );
    }
  }

  return TagSelector;
};

export default compose(
  connect(
    (state) => ({
      project: state.project,
      surveyPublic: state.surveyPublic,
      tagCategories: state.tagCategoriesSelector,
    }),
    {
      getTagCategoriesPublic: tagCategoriesSelectorApi.findAll,
      getTagCategoriesPrivate: tagCategoriesSelectorApi.findAllPerProjectNested,
    }
  ),
  withAccessSupport
);
